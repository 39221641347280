import { useLayoutEffect, useRef, useState } from 'react';

import styled, { isPropValid } from '@mint-lib/styled';
import {
  BaseTextFieldProps,
  TextFieldProps as TextFieldPropsM,
} from '@mui/material/TextField';
import { TooltipProps as TooltipPropsM } from '@mui/material/Tooltip';

import FormHelperText from '../FormHelperText/FormHelperText.jsx';
import Icon from '../Icon/Icon.jsx';
import TextField from '../TextField/TextField.jsx';
import Truncation from '../Truncation/Truncation.jsx';
import Typography from '../Typography/Typography.jsx';

export interface TextareaProps extends BaseTextFieldProps {
  /**
   * The label content.
   */
  label?: string;
  /**
   * The helper text content.
   */
  helperText?: string;
  /**
   * The short hint displayed in the input before the user enters a value.
   */
  placeholder?: string;
  /**
   * The value of the `Input` element, required for a controlled component.
   */
  value?: TextFieldPropsM['value'];
  /**
   * This prop helps users to fill forms faster, especially on mobile devices.
   * The name can be confusing, as it's more like an autofill.
   * You can learn more about it: https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill.
   */
  /** @uxpinignoreprop */
  autoComplete?: string;
  /**
   * Override or extend the styles applied to the component. See CSS API: https://mui.com/api/text-field/#css.
   */
  /** @uxpinignoreprop */
  classes?: TextFieldPropsM['classes'];
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default primary
   */
  color?: 'primary' | 'secondary';
  /**
   * If 'dense' or 'normal', will adjust vertical spacing of this and contained components.
   */
  margin?: TextFieldPropsM['margin'];
  /** The default value. Use when the component is not controlled. */
  /** @uxpinignoreprop */
  defaultValue?: string;
  /** If 'true', the component is disabled. */
  disabled?: boolean;
  /**
   * If `true`, the label is displayed as required and the input will be required.
   */
  required?: boolean;
  /** If 'true', the label is displayed in an error state. */
  error?: boolean;
  /**
   * If 'true', the input will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * If 'true', the input element is focused during the first mount.
   */
  autoFocus?: boolean;
  /**
   * The id of the input element. Use this prop to make label and helperText accessible for screen readers.
   */
  /** @uxpinignoreprop */
  id?: string;
  /**
   * Callback fired when the value is changed.
   */
  onChange?: TextFieldPropsM['onChange'];
  /** The system prop that allows defining
   * system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/ */
  /** @uxpinignoreprop */
  sx?: TextFieldPropsM['sx'];
  InputLabelProps?: TextFieldPropsM['InputLabelProps'];
  InputProps?: TextFieldPropsM['InputProps'];
  /**
   * Maximum number of characters
   */
  maxLength?: number;
  /**
   * Maximum number of rows to display when multiline option is set to true.
   */
  maxRows?: number | string;
  /**
   * Minimum number of rows to display when multiline option is set to true.
   */
  minRows?: number | string;
  /**
   *  Text to render tooltip for information
   */
  infoText?: string;
  /**
   * Tooltip props
   */
  TooltipProps?: Partial<TooltipPropsM>;
  /**
   * Value of decimals limit
   */
  warning?: boolean;
  /**
   * readOnly
   */
  readOnly?: boolean;
}
function Textarea({
  InputLabelProps,
  helperText,
  maxLength,
  infoText,
  TooltipProps,
  warning,
  label,
  fullWidth,
  readOnly,
  ...props
}: TextareaProps) {
  const maxLengthRef = useRef<HTMLSpanElement>(null);
  const [maxLengthWidth, setMaxLengthWidth] = useState<string | null>(null);
  useLayoutEffect(() => {
    if (maxLengthRef && maxLengthRef.current) {
      setMaxLengthWidth(maxLengthRef.current.clientWidth + 1 + 'px');
    }
  });
  const helperTextWidth = () => {
    if (fullWidth && maxLength) {
      return `calc(100% - ${maxLengthWidth})`;
    }
    if (!fullWidth && maxLength) {
      return `calc(200px - ${maxLengthWidth})`;
    }
    if (!fullWidth && !maxLength) {
      return `200px`;
    }
    if (fullWidth && !maxLength) {
      return `100%`;
    }
  };
  return (
    <Container $fullWidth={!!fullWidth}>
      <TextField
        rows={4}
        {...props}
        multiline
        fullWidth={fullWidth}
        label={label}
        readOnly={readOnly}
        warning={warning}
        infoText={infoText}
        inputProps={{
          ...props.inputProps,
          maxLength: maxLength,
        }}
      />
      <HelperText>
        {helperText ? (
          <StyledFormHelperText
            sx={{ width: helperTextWidth() }}
            $warning={warning}
            error={props.error}
            disabled={props.disabled}
          >
            {warning && !props.error ? (
              <Icon component="WarningAltFilled" />
            ) : null}
            {props.error ? <Icon component="WarningFilled" /> : null}
            <Truncation
              tooltipTitle={helperText ? helperText : ''}
              tooltipPlacement="bottom"
              variant="helperText01"
            >
              {helperText}
            </Truncation>
          </StyledFormHelperText>
        ) : (
          <div />
        )}
        {maxLength && typeof props.value === 'string' ? (
          <Typography
            ref={maxLengthRef}
            align="right"
            variant="helperText01"
            color={
              props.disabled
                ? (theme) => theme.palette.action.disabled01
                : (theme) => theme.palette.text.secondary
            }
            paddingTop="4px"
          >
            {props.value.length}/{maxLength}
          </Typography>
        ) : null}
      </HelperText>
    </Container>
  );
}
export default Textarea;
const Container = styled('div', { shouldForwardProp: isPropValid })<{
  $fullWidth: boolean;
}>`
  width: ${({ $fullWidth }) => ($fullWidth ? '100%' : '200px')};
`;
const HelperText = styled('div')`
  display: flex;
  justify-content: space-between;
  gap: 3px;
`;
const StyledFormHelperText = styled(FormHelperText, {
  shouldForwardProp: (prop) => prop !== '$warning',
})<{
  $warning?: boolean;
}>(({ $warning, theme }) => ({
  display: 'flex',
  gap: '5px',
  color: $warning ? theme.palette.warning.main : '',
}));
