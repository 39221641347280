import { ColumnDef, InitialTableState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { useIsAgencyCompany, useProfile } from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';

import { Box, Icon, Tooltip } from '@myn/mui';

import { useAgencyClients } from '../../api/queries/agencyClients.js';
import { useUsers } from '../../api/queries/usersData.js';
import { UserType } from '../../api/users/users.types.js';
import RegularTextCell from '../../atoms/RegularTextCell/RegularTextCell.jsx';
import UserCell from '../../atoms/UserCell/index.js';
import { UserCellTypeNew } from '../../atoms/UserCell/UserCell.types.js';
import useTableSorting from '../../hooks/useTableSorting/useTableSorting.jsx';
import ClientsModal from '../../organisms/ClientsModal/ClientsModal.jsx';
import LabelModal from '../../organisms/LabelModal/LabelModal.jsx';
import PermissionsTable from '../../organisms/PermissionsTable/PermissionsTable.jsx';
import { isEqual } from '../../organisms/UserModal/helpers.js';
import UserModal from '../../organisms/UserModal/UserModal.jsx';
import { HandleSubmitPramsType } from '../../organisms/UserModal/userModal.types.js';
import ActionsCell from './ActionsCell/index.js';
import CarbonOffsetCell from './CarbonOffsetCell/CarbonOffsetCell.jsx';
import ClientsCell from './ClientsCell/ClientsCell.jsx';
import LabelCell from './LabelCell/LabelCell.jsx';
import StatusCell from './StatusCell/StatusCell.jsx';

type Props = {
  data: UserType[];
  isReadonlyPermission: boolean;
  dataIsLoading: boolean;
  noResultsStatus: boolean;
};

//TODO add virtualize or pagination?

const UserTable = ({
  data,
  isReadonlyPermission,
  dataIsLoading,
  noResultsStatus,
}: Props) => {
  const { t } = useTranslation('@myn/permissions');
  const ff = useFlags('@mint/arm', ['ssoIdpProvider']);

  const { profile, profileService } = useProfile();

  const { editUser, refetchUsers } = useUsers();
  const { assignClients } = useAgencyClients();

  const [activeCell, setActiveCell] = useState<UserType | null>(null);
  const [modalType, setModalType] = useState<
    'label' | 'user' | 'clients' | null
  >(null);
  const isAgencyCompany = useIsAgencyCompany();
  const isIdpProvider = profileService.isIdpProvider();

  useTableSorting();

  const columns: ColumnDef<UserType>[] = useMemo(
    () => [
      {
        id: 'user',
        meta: { field: 'full_name' },
        accessorFn: ({ firstName, lastName, ffId, agencyUser }) => {
          return {
            firstName,
            lastName,
            ffId,
            isMintUser: profile?.email?.includes('@mint.ai'),
            agencyUser,
          };
        },
        cell: (info) => {
          return <UserCell {...(info.getValue() as UserCellTypeNew)} />;
        },

        header: () => t('Name'),
      },
      {
        accessorFn: (row) => row?.email,
        id: 'email',
        meta: { field: 'email' },
        cell: (info) => (
          <RegularTextCell text={info.getValue() as UserType['email']} />
        ),
        header: () => t('Email'),
      },
      {
        accessorFn: (row) => row?.role,
        meta: { field: 'role_name' },
        id: 'role',
        cell: (info) => <RegularTextCell text={String(info.getValue())} />,
        header: () => t('Role'),
      },
      {
        id: 'tag',
        cell: (info) => {
          return (
            <LabelCell
              {...info.row.original}
              onClick={(user: UserType) => {
                setActiveCell(user);
                setModalType('label');
              }}
            />
          );
        },
        header: () => t('Label'),
        enableSorting: false,
      },
      {
        id: 'carbon',
        meta: { field: 'email_subscriptions' },
        cell: (info) => {
          return (
            <CarbonOffsetCell
              {...info.row.original}
              isReadonlyPermission={isReadonlyPermission}
            />
          );
        },
        header: () => (
          <Box
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {t('Carbon Offset emails')}
          </Box>
        ),
      },
      {
        id: 'clients',
        cell: (info) => {
          return (
            <ClientsCell
              {...info.row.original}
              onClick={(user: UserType) => {
                setActiveCell(user);
                setModalType('clients');
              }}
            />
          );
        },
        header: () => (
          <Box
            sx={{
              whiteSpace: 'nowrap',
            }}
          >
            {t('Assigned clients')}
          </Box>
        ),
        enableSorting: false,
      },
      {
        id: 'status',
        meta: { field: 'is_active_user' },
        cell: (info) => {
          return (
            <StatusCell
              {...info.row.original}
              isReadonlyPermission={isReadonlyPermission}
            />
          );
        },
        header: () => (
          <Box sx={{ display: 'flex', gap: (theme) => theme.spacing(1) }}>
            {t('Activate/Deactivate')}
            <Tooltip
              title={<>{t('Deactivate or activate the selected profile')}</>}
              placement="top"
              arrow
            >
              <Box>
                <Icon component="Information" color="secondary" />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      {
        id: 'actions',
        cell: (info) => {
          return (
            <ActionsCell
              {...info.row.original}
              onEditClick={(user: UserType) => {
                setModalType('user');
                setActiveCell(user);
              }}
              isReadonlyPermission={isReadonlyPermission}
            />
          );
        },
        header: () => null,
        enableSorting: false,
      },
    ],
    [],
  );

  const handleEditUser = async (params: HandleSubmitPramsType) => {
    if (activeCell === null) {
      return Promise.reject('active cell is null');
    }

    // if passwd clients and its not equal previous then assign new
    if (
      isAgencyCompany &&
      params?.clients &&
      !isEqual(params.clients, activeCell.assignedClients) &&
      activeCell.id
    ) {
      try {
        await assignClients.mutateAsync({
          clients: params.clients,
          user: activeCell.id,
        });
      } catch (error) {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t('Failed to assign clients to the user.'),
        });

        return null;
      }
    }

    try {
      const result = await editUser.mutateAsync(
        { ...params.userData, id: activeCell.id },
        {
          onSuccess: () => {
            setActiveCell(null);

            enqueueSnackbar({
              removable: true,
              variant: 'success',
              title: t('Success'),
              subTitle: t('The user has been updated successfully'),
            });

            refetchUsers();
          },
          onError: () => {
            enqueueSnackbar({
              removable: true,
              variant: 'error',
              title: t('Error'),
              subTitle: t(
                'An error occurred while updating the user. Please try again',
              ),
            });
          },
        },
      );

      return result;
    } catch {
      enqueueSnackbar({
        removable: true,
        variant: 'error',
        title: t('Error'),
        subTitle: t(
          'An error occurred while updating the user. Please try again',
        ),
      });

      return null;
    }
  };

  const initialState: InitialTableState = useMemo(() => {
    return {
      columnVisibility: {
        clients: isAgencyCompany,
        tag: !isAgencyCompany,
        carbon: !isAgencyCompany,
        status: !ff.ssoIdpProvider.enabled && !isIdpProvider,
      },
    };
  }, [isAgencyCompany]);

  return (
    <>
      <PermissionsTable<UserType>
        data={data}
        columns={columns}
        initialState={initialState}
        dataIsLoading={dataIsLoading}
        noResultsStatus={noResultsStatus}
      />
      {!!activeCell && modalType === 'user' ? (
        <UserModal
          onClose={() => setActiveCell(null)}
          onSubmit={handleEditUser}
          initialValues={activeCell ?? {}}
          isActive={!!activeCell}
          isReadonlyPermission={isReadonlyPermission}
          initialView={'existingUserView'}
        />
      ) : null}
      {!!activeCell && modalType === 'label' ? (
        <LabelModal
          onClose={() => {
            setActiveCell(null);
          }}
          userProfile={activeCell}
          isActive={!!activeCell}
        />
      ) : null}
      {!!activeCell && modalType === 'clients' ? (
        <ClientsModal
          onClose={() => {
            setActiveCell(null);
          }}
          userProfile={activeCell}
          isActive={!!activeCell}
        />
      ) : null}
    </>
  );
};

export default UserTable;
