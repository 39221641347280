/// <reference types="vite-plugin-pwa/client" />
/// <reference types="vite-plugin-pwa/info" />
import { Auth0Provider } from '@auth0/auth0-react';
import React, { useEffect, useMemo, useState } from 'react';
import { createRoot } from 'react-dom/client';

import i18n, { I18nextProvider, i18nInitPromise } from '@mint-lib/i18n';
import { Toaster } from '@mint-lib/notifications';
import {
  AuthProvider,
  FlagsmithProvider,
  MintServiceLocator,
} from '@mint-lib/routing-context';
import getTheme, { GlobalStyles, ThemeProvider } from '@mint-lib/theme';

import { CssBaseline } from '@myn/mui/material';

import './index.css';
import './theme-support.css';

import AxeptIoLoader from '@mint-lib/axeptIo';
import { routerContext, routerInstance, RouterProvider } from '@mint/routing';
import { registerSW } from 'virtual:pwa-register';

import { muteConsole } from './utils/muteConsole.js';

registerSW({ immediate: true });

type ThemeModeType = 'dark' | 'light';

const storedThemeValue = localStorage.getItem('theme') as ThemeModeType;
const isValidStoredThemeValue =
  storedThemeValue === 'light' || storedThemeValue === 'dark';

// watch for auth events
routerContext.serviceLocator.getEventBus().on('auth', 'auth', () => {
  if (routerInstance.state.matches[1].routeId.startsWith('/_auth')) {
    routerInstance.invalidate().finally(() => {
      routerInstance.navigate({
        to:
          'next' in routerInstance.latestLocation.search
            ? decodeURIComponent(
                (routerInstance.latestLocation.search.next as string) ?? '/',
              )
            : '/',
      });
    });
  }
});

routerContext.serviceLocator.getEventBus().on('auth', 'logout', () => {
  routerInstance.invalidate().finally(() => {
    window.location.reload();
    // routerInstance.navigate({
    //   to: '/',
    // });
  });
});

async function boot() {
  muteConsole();

  const credencials = await routerContext.getAuth0Credentials?.();
  const AppWrapper = () => {
    const [themeMode, setThemeMode] = useState<ThemeModeType>(
      isValidStoredThemeValue ? storedThemeValue : 'light',
    );
    useEffect(() => {
      document.documentElement.setAttribute('data-color-scheme', themeMode);
    }, [themeMode]);

    useEffect(() => {
      const channel = new BroadcastChannel('themeChannel');
      channel.onmessage = (event) => {
        const themeValue = event.data as ThemeModeType;
        if (themeValue === 'light' || themeValue === 'dark') {
          setThemeMode(event.data);
        }
      };
      return () => {
        channel.close();
      };
    }, []);

    const theme = useMemo(
      () => ({
        ...getTheme(themeMode),
      }),
      [themeMode],
    );

    return (
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <MintServiceLocator.Provider value={routerContext.serviceLocator}>
            <FlagsmithProvider name="@mint/app">
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                <AuthProvider>
                  <Auth0Provider
                    domain={credencials?.domain ?? ''}
                    clientId={credencials?.applicationClientId ?? ''}
                    authorizationParams={{
                      redirect_uri: window.location.origin + '/login-auth0',
                    }}
                  >
                    <RouterProvider
                      router={routerInstance}
                      context={routerContext}
                    />
                    <AxeptIoLoader />
                    <Toaster />
                  </Auth0Provider>
                </AuthProvider>
              </ThemeProvider>
            </FlagsmithProvider>
          </MintServiceLocator.Provider>
        </I18nextProvider>
      </React.StrictMode>
    );
  };
  // * Do not forget to update packages/shared/src/testing/render.tsx wrapper
  return i18nInitPromise.then(() =>
    createRoot(document.getElementById('root') as Element).render(
      <AppWrapper />,
    ),
  );
}
boot().catch((e) => {
  // eslint-disable-next-line no-console
  console.error(e);
});
