import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

// @ts-ignore: need to upgrade or patch package
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import {
  ClientType,
  useIsAgencyCompany,
  useProfile,
} from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';

import { Box, Dialog, LoadingButton, OptionType } from '@myn/mui';

import { useAgencyClients } from '../../api/queries/agencyClients.js';
import { useUserRole } from '../../api/queries/userRoles.js';
import { useUsers } from '../../api/queries/usersData.js';
import { UserType, type UserStatus } from '../../api/users/index.js';
import useAlert from '../../hooks/useAlert/useAlert.jsx';
import useUserModalView from '../../hooks/useUserModalView/useUserModalView.jsx';
import { nullSafeFn } from '../../utils/index.js';
import {
  HandleSubmitPramsType,
  type UserModalViewType,
} from './userModal.types.js';
import { UserModalType, UserModalValidationSchema } from './validation.js';

export const SUPPORTED_AVATAR_FORMATS = ['.jpeg', '.png', '.gif', '.jpg'];

const EMPTY_VALUES: UserModalType = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  avatar: null,
  assignedClients: [],
  role: '',
  mobileNumber: '',
  officeNumber: '',
  isActive: true,
};

const nextStepMapping: Record<UserStatus, UserModalViewType> = {
  not_found: 'nonExistingUserView',
  exists: 'existingUserView',
  deactivated: 'emailView',
  exists_in_your_workspace: 'emailView',
};

type Props = {
  className?: string;
  onClose: () => void;
  onSubmit: (
    params: HandleSubmitPramsType,
  ) => Promise<UserType | null | undefined>;
  initialValues?: UserModalType;
  isActive: boolean;
  isReadonlyPermission: boolean;
  initialView?: UserModalViewType;
};

// TODO temporary move this helper to file because of imports problem
type GenericListType = { id: number; name: string };

const mapListToSelect = (list: GenericListType[]): OptionType[] =>
  list.map((option) => ({ value: option?.id, label: option?.name }));

const UserModalNew = ({
  onClose,
  onSubmit,
  initialValues = EMPTY_VALUES,
  isActive,
  isReadonlyPermission,
  initialView = 'emailView',
}: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags('@myn/permissions', ['newCheckEmail']);

  const ffArm = useFlags('@mint/arm', ['ssoIdpProvider', 'userManagemetFixes']);

  const { profileService } = useProfile();

  const isIdpProvider = profileService.isIdpProvider();
  const isAgencyCompany = useIsAgencyCompany();
  const { agencyClients } = useAgencyClients();
  const { checkUserByEmail, checkUserByEmailNew } = useUsers({
    disableUsersFetch: true,
  });

  const [assignedClients, setAssignedClients] = useState<number[] | null>(null);

  const [currentView, setCurrentView] =
    useState<UserModalViewType>(initialView);

  const [isUserExist, setIsUserExist] = useState<boolean | null>(null);

  const {
    userRoleData,
    userRole: { isLoading: userRoleLoading },
  } = useUserRole({ getFullList: true });

  const roleOptions = useMemo(
    () => mapListToSelect(userRoleData ?? []),
    [userRoleData],
  );

  const clientsOptions = useMemo(
    () =>
      nullSafeFn<ClientType[] | null, OptionType[]>(
        mapListToSelect,
        agencyClients,
      ),
    [agencyClients],
  );

  const isLoading = userRoleLoading || !roleOptions?.length;

  const hasInitialValues = useMemo(
    () => Object.keys(initialValues).length > 0,
    [initialValues],
  );

  const defaultValues: UserModalType = useMemo(() => {
    const initialRole = roleOptions?.find(
      (item) => item.label === initialValues.role,
    )?.value;

    // To keep is dirty state for the form we should pass all the values
    const values = hasInitialValues ? initialValues : EMPTY_VALUES;

    return { ...values, role: initialRole || '' };
  }, [initialValues, roleOptions]);

  const formInstance = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: zodResolver(UserModalValidationSchema(t)),
  });

  const handleEmailChange = useCallback(
    (email: string) => {
      if (formInstance.formState.errors?.email || !email) {
        setIsUserExist(null);
        return;
      }

      if (ff.newCheckEmail.enabled) {
        checkUserByEmailNew.mutateAsync(email, {
          onSuccess: (data) => {
            setIsUserExist(true);
            formInstance.reset({ ...data.user, email });

            if (data.status) {
              setCurrentView(nextStepMapping[data.status]);
            }

            if (data.status === 'exists_in_your_workspace') {
              formInstance.setError('email', {
                type: 'custom',
                message: t(`This user is already in the workspace.`),
              });
            }

            if (data.status === 'deactivated') {
              formInstance.setError('isActive', {
                type: 'custom',
                message: 'deactivated',
              });
            }
          },
          onError: () => {
            enqueueSnackbar({
              variant: 'error',
              title: t('Error'),
              subTitle: t(
                'Something went wrong while checking the user. Please try again.',
              ),
            });
          },
        });

        return;
      }

      checkUserByEmail.mutateAsync(email, {
        onSuccess: (data) => {
          setIsUserExist(true);
          formInstance.reset({ ...data, email });
          setCurrentView('existingUserView');
        },
        onError: () => {
          setCurrentView('nonExistingUserView');
          setIsUserExist(false);
        },
      });
    },
    [checkUserByEmail, formInstance],
  );

  const handleSubmitForm: SubmitHandler<UserModalType> = useCallback(
    (values) => {
      const { firstName, lastName, email, mobileNumber, officeNumber, role } =
        values;

      if (!role || !email) {
        return;
      }

      onSubmit({
        userData: {
          firstName,
          lastName,
          email,
          mobileNumber,
          officeNumber,
          role,
        },
        isUserExist: !!isUserExist,
        clients: assignedClients ?? [],
      });
    },
    [assignedClients, isUserExist, onSubmit],
  );

  const handleSubmitButtonClick = useCallback(() => {
    if (
      isReadonlyPermission ||
      (ffArm.ssoIdpProvider.enabled && isIdpProvider)
    ) {
      onClose();
    } else if (currentView === 'emailView' && formInstance.getValues('email')) {
      handleEmailChange(formInstance.getValues('email'));
    } else {
      formInstance.handleSubmit(handleSubmitForm)();
    }
  }, [
    currentView,
    formInstance,
    handleEmailChange,
    handleSubmitForm,
    isReadonlyPermission,
  ]);

  const alert = useAlert({ currentView, formInstance, hasInitialValues });
  const view = useUserModalView({
    currentView,
    formInstance,
    isReadonlyPermission,
    roleOptions,
    clientsOptions,
    assignedClients,
    setAssignedClients,
    isLoading,
  });

  useEffect(() => {
    setAssignedClients(initialValues.assignedClients ?? null);
    formInstance.setValue(
      'assignedClients',
      initialValues.assignedClients || [],
    );
  }, [initialValues.assignedClients, formInstance]);

  useEffect(() => {
    if (ffArm.userManagemetFixes.enabled && hasInitialValues) {
      formInstance.reset(defaultValues);
    }
  }, [hasInitialValues, defaultValues, ffArm]);

  return (
    <FormProvider {...formInstance}>
      <Dialog
        open={isActive}
        onClose={onClose}
        title={
          ffArm.userManagemetFixes.enabled
            ? isReadonlyPermission ||
              (ffArm.ssoIdpProvider.enabled && isIdpProvider)
              ? t(`User settings`)
              : hasInitialValues
                ? t('Edit user')
                : t('New user')
            : t('New user')
        }
        size="s"
        submitButton={
          <LoadingButton
            loadingPosition="start"
            variant="contained"
            size="large"
            loading={checkUserByEmail.isPending}
            onClick={handleSubmitButtonClick}
            disabled={
              !isReadonlyPermission &&
              !ffArm.ssoIdpProvider.enabled &&
              !isIdpProvider &&
              (!!Object.keys(formInstance.formState.errors)?.length ||
                !formInstance.formState.isDirty)
            }
            color="primary"
          >
            {currentView === 'emailView'
              ? t('Next')
              : isReadonlyPermission ||
                  (ffArm.ssoIdpProvider.enabled && isIdpProvider)
                ? t('Close')
                : t('Save')}
          </LoadingButton>
        }
        cancelButtonProps={
          isReadonlyPermission ||
          (ffArm.ssoIdpProvider.enabled && isIdpProvider)
            ? undefined
            : {
                label: t('Cancel'),
                onClick: onClose,
                variant: 'text',
              }
        }
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {alert}
          {view}
        </Box>
      </Dialog>
    </FormProvider>
  );
};

export default UserModalNew;
