import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { OptionType } from '@myn/mui';

import EmailView from '../../organisms/UserModalNew/EmailView.jsx';
import ExistingUserView from '../../organisms/UserModalNew/ExistingUserView.jsx';
import NonExistingUserView from '../../organisms/UserModalNew/NonExistingUserView.jsx';
import type { UserModalViewType } from '../../organisms/UserModalNew/userModal.types.js';
import { UserModalType } from '../../organisms/UserModalNew/validation.js';

export type UserModalViewProps = {
  currentView: UserModalViewType;
  formInstance: UseFormReturn<UserModalType>;
  isReadonlyPermission: boolean;
  roleOptions: OptionType[];
  clientsOptions: OptionType[] | null;
  assignedClients: number[] | null;
  setAssignedClients: Dispatch<SetStateAction<number[] | null>>;
  isLoading: boolean;
};

const useUserModalView = ({
  currentView,
  formInstance,
  isReadonlyPermission,
  roleOptions,
  clientsOptions,
  assignedClients,
  setAssignedClients,
  isLoading,
}: UserModalViewProps) => {
  const userModalView = useMemo(
    () => ({
      emailView: () => (
        <EmailView formInstance={formInstance} isLoading={isLoading} />
      ),
      existingUserView: () => (
        <ExistingUserView
          formInstance={formInstance}
          isReadonlyPermission={isReadonlyPermission}
          roleOptions={roleOptions}
          clientsOptions={clientsOptions}
          assignedClients={assignedClients}
          setAssignedClients={setAssignedClients}
          isLoading={isLoading}
        />
      ),
      nonExistingUserView: () => (
        <NonExistingUserView
          formInstance={formInstance}
          isReadonlyPermission={isReadonlyPermission}
          roleOptions={roleOptions}
          clientsOptions={clientsOptions}
          assignedClients={assignedClients}
          setAssignedClients={setAssignedClients}
          isLoading={isLoading}
        />
      ),
    }),
    [
      currentView,
      formInstance,
      isReadonlyPermission,
      roleOptions,
      clientsOptions,
      assignedClients,
      setAssignedClients,
      isLoading,
    ],
  );

  return userModalView[currentView]();
};

export default useUserModalView;
