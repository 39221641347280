import { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';

import { useFlags } from '@mint-lib/flags';
import { useTranslation } from '@mint-lib/i18n';
import { useIsAgencyCompany } from '@mint-lib/profile-helpers';

import { Alert } from '@myn/mui';

import { Trans } from '../../molecules/Trans/index.js';
import type { UserModalViewType } from '../../organisms/UserModalNew/userModal.types.js';
import { UserModalType } from '../../organisms/UserModalNew/validation.js';

export type UserModalViewProps = {
  currentView: UserModalViewType;
  formInstance: UseFormReturn<UserModalType>;
  hasInitialValues: boolean;
};

const useAlert = ({
  currentView,
  formInstance,
  hasInitialValues,
}: UserModalViewProps) => {
  const ff = useFlags(['newCheckEmail']);

  const { t } = useTranslation('@myn/permissions');

  const isAgencyCompany = useIsAgencyCompany();

  const alert = useMemo(() => {
    if (formInstance.formState.errors.isActive?.message === 'deactivated') {
      return (
        <Alert
          title={t(`This user can't be added to the workspace.`)}
          severity="error"
          subTitle={
            ff.newCheckEmail.enabled ? (
              t(`Contact Mint ARM support if you need to add this user.`)
            ) : (
              <Trans
                i18nKey={`Reach out to Mint ARM support <emailLink>{{email}}</emailLink> if you need to re-activate the user.`}
                t={t}
                values={{ email: t(`support@mint.ai`) }}
              />
            )
          }
        />
      );
    }

    if (
      !hasInitialValues &&
      currentView === 'nonExistingUserView' &&
      !isAgencyCompany
    ) {
      return (
        <Alert
          title={t(
            'Remember to assign “Brand & Geographies” to New users or they won`t see any campaign.',
          )}
          severity="info"
          subTitle={t(
            `You can do so from the “Brand & Geographies” section of the “Company Profile” page.`,
          )}
        />
      );
    }

    return null;
  }, [
    currentView,
    JSON.stringify(formInstance.formState.errors),
    formInstance,
    hasInitialValues,
    t,
  ]);

  return alert;
};

export default useAlert;
