import React from 'react';

import SkeletonM, {
  SkeletonProps as SkeletonPropsM,
} from '@mui/material/Skeleton';

export interface SkeletonProps extends SkeletonPropsM {
  /**
   * The animation. If false the animation effect is disabled.
   */
  animation?: SkeletonPropsM['animation'];
  /**
   * Optional children to infer width and height from.
   * @uxpinpignoreprop
   * */
  children?: React.ReactNode;
  /**
   * Override or extend the styles applied to the component. See CSS API below for more details.
   */
  classes?: SkeletonPropsM['classes'];
  /**
   * The component used for the root node. Either a string to use a HTML element or a component.
   */
  component?: React.ElementType;
  /**
   * Height of the skeleton. Useful when you don't want to adapt the skeleton to a text element but for instance a card.
   */
  height?: number;
  /**
   * The type of content that will be rendered.
   */
  variant?: SkeletonPropsM['variant'];
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * @uxpinignoreprop
   */
  sx?: SkeletonPropsM['sx'];
}

function Skeleton({ sx, ...props }: SkeletonProps) {
  return (
    <SkeletonM
      animation="wave"
      sx={{
        backgroundColor: (theme) => theme.palette.ui.active,
        ...sx,
      }}
      {...props}
    >
      {props.children}
    </SkeletonM>
  );
}

export default Skeleton;
