import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { Tokens } from '@mint-lib/api';
import { AuthContext } from '@mint-lib/auth';
import { TFunction, useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import {
  fetchOriginUserProfile,
  usePermissions,
  useProfile,
} from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';
import { useNavigate } from '@mint/routing';

import { Button, Tooltip } from '@myn/mui';

import { selectWorkspace } from '../../api/workspaces/workspaces.js';
import { PERMISSIONS_MAP } from '../../constants.js';
import { useModal } from '../../context/ModalContext/useModal.js';
import useTab, { RouteTabs } from '../../hooks/useTab/useTab.js';

export const CreateModalLabelMap: (
  t: TFunction,
) => Record<RouteTabs, string> = (t: TFunction) => ({
  [RouteTabs.User]: t('New user'),
  [RouteTabs.Role]: t('New role'),
});

const AddButton = () => {
  const { t } = useTranslation('@myn/permissions');
  const ff = useFlags('@myn/guest', ['ssoLogin']);
  const ffNew = useFlags('@mint/arm', ['ssoIdpProvider']);

  const auth = useContext(AuthContext);

  const { profile, setProfileData, profileService } = useProfile();

  const [tokens, setTokens] = useState<Tokens | null>(null);

  const { setOpen: setIsNewModalOpened } = useModal();

  const navigate = useNavigate();

  const activeRoute = useTab();

  const { getPermissionField } = usePermissions();

  const isIdpProvider = profileService.isIdpProvider();
  const agencyWorkspaceId = profileService.getAgencyWorkspaceId();

  const isReadonlyPermission = useMemo(
    () =>
      getPermissionField('usersPermissionsFeature') === PERMISSIONS_MAP.View,
    [getPermissionField('usersPermissionsFeature')],
  );

  const label = CreateModalLabelMap(t)[activeRoute ?? RouteTabs.User];

  useEffect(() => {
    if (tokens) {
      auth.saveTokens(tokens);

      // update profile data to handle checkmark for active WS
      fetchOriginUserProfile()
        .then((res) => {
          setProfileData(res);
        })
        .then(() => {
          navigate({
            to: '/agency-hub/users-management/user',
          });
        })
        .catch(() => {
          enqueueSnackbar({
            variant: 'error',
            title: t('Error'),
            subTitle: t('Something went wrong. Please try again'),
            removable: true,
          });
        });
    }
  }, [tokens]);

  const handleRedirectToAgency = useCallback(() => {
    const implyUrl =
      profile &&
      // eslint-disable-next-line
      `${new URL(profile?.profile?.company?.dashboardUrl)?.origin}/logout`;

    if (implyUrl) {
      localStorage.setItem('implyLogout', implyUrl);
    }

    selectWorkspace(setTokens, agencyWorkspaceId);
  }, [navigate]);

  if (ffNew.ssoIdpProvider.enabled && isIdpProvider && activeRoute === 'user') {
    return null;
  }

  return !!agencyWorkspaceId &&
    ff.ssoLogin.enabled &&
    activeRoute === 'user' ? (
    <Tooltip
      title={t(
        'To add a new user you’ll be redirected to your agency workspace.',
      )}
      placement="bottom-end"
    >
      <Button
        variant="contained"
        color="primary"
        size="large"
        onClick={() => handleRedirectToAgency()}
        startIcon={'Launch'}
      >
        {label}
      </Button>
    </Tooltip>
  ) : isReadonlyPermission ? null : (
    <Button
      variant="contained"
      color="primary"
      size="large"
      onClick={() => setIsNewModalOpened(true)}
      startIcon={'Add'}
    >
      {label}
    </Button>
  );
};

export default AddButton;
