import { useState } from 'react';

import { useTranslation } from '@mint-lib/i18n';
import { enqueueSnackbar } from '@mint-lib/notifications';
import { useProfile } from '@mint-lib/profile-helpers';
import { useFlags } from '@mint-lib/routing-context';

import { Box, Dialog, IconButton, Tooltip } from '@myn/mui';

import { useUsers } from '../../../api/queries/usersData.js';
import { UserType } from '../../../api/users/index.js';

export type Props = UserType & {
  onEditClick: (user: UserType) => void;

  isReadonlyPermission: boolean;
};

const ActionsCell = ({
  onEditClick,
  isReadonlyPermission,
  ...value
}: Props) => {
  const { t } = useTranslation('@myn/permissions');

  const ff = useFlags('@mint/arm', ['ssoIdpProvider']);

  const { profile, profileService } = useProfile();

  const isIdpProvider = profileService.isIdpProvider();

  const { deleteUser, refetchUsers, resendUserInvitation } = useUsers();

  const [isDeleteOpened, setIsDeleteOpened] = useState(false);

  const handleDelete = () => {
    setIsDeleteOpened(false);
    deleteUser.mutateAsync(value.id as string | number, {
      onSuccess: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t('The user has been deleted successfully'),
        });

        refetchUsers();
      },

      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            'An error occurred while deleting the user. Please try again',
          ),
        });
      },
    });
  };

  const handleResendUserInvitation = () => {
    resendUserInvitation.mutateAsync(value.id as string | number, {
      onSuccess: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'success',
          title: t('Success'),
          subTitle: t(
            'Invitation for {{firstName}} {{lastName}} was successfully send.',
            {
              firstName: value.firstName,
              lastName: value.lastName,
            },
          ),
        });

        refetchUsers();
      },

      onError: () => {
        enqueueSnackbar({
          removable: true,
          variant: 'error',
          title: t('Error'),
          subTitle: t(
            `An error occurred while sending invitation to the user. Please try again`,
          ),
        });
      },
    });
  };

  const dialogTitleValue = `${value.firstName + ' ' + value.lastName}`;

  return (
    <Box
      sx={{
        display: 'flex',
      }}
    >
      {isReadonlyPermission || (ff.ssoIdpProvider.enabled && isIdpProvider) ? (
        <>
          <Tooltip
            title={
              ff.ssoIdpProvider.enabled && isIdpProvider
                ? t(`User settings`)
                : t(`View access`)
            }
          >
            <IconButton
              variant="icon"
              onClick={() => {
                onEditClick(value);
              }}
              color="secondary"
              icon={'View'}
            />
          </Tooltip>
          {ff.ssoIdpProvider.enabled && isIdpProvider && (
            <Tooltip title={t(`Send invitation`)} disableInteractive>
              <IconButton
                variant="icon"
                onClick={handleResendUserInvitation}
                disabled={isReadonlyPermission}
                color="secondary"
                icon={'SendAlt'}
                aria-label="Send-action"
              />
            </Tooltip>
          )}
        </>
      ) : (
        <>
          <Tooltip title={t(`Edit access`)} disableInteractive>
            <IconButton
              variant="icon"
              onClick={() => {
                onEditClick(value);
              }}
              color="secondary"
              icon={'Edit'}
              aria-label="Edit-action"
            />
          </Tooltip>
          <Tooltip title={t(`Send invitation`)} disableInteractive>
            <IconButton
              variant="icon"
              onClick={handleResendUserInvitation}
              disabled={isReadonlyPermission}
              color="secondary"
              icon={'SendAlt'}
              aria-label="Send-action"
            />
          </Tooltip>
          <Tooltip title={t(`Remove`)} disableInteractive>
            <IconButton
              variant="icon"
              onClick={() => {
                setIsDeleteOpened(true);
              }}
              disabled={profile?.id === value?.id || isReadonlyPermission}
              color="secondary"
              icon={'Delete'}
              aria-label="Delete-action"
            />
          </Tooltip>
        </>
      )}

      {isDeleteOpened && (
        <Dialog
          open={isDeleteOpened}
          onClose={() => setIsDeleteOpened(false)}
          title={t('Are you sure you want to remove "{{value}}"?', {
            value: dialogTitleValue,
          })}
          sx={{
            '.MuiTypography-h3': {
              overflow: 'hidden',
              width: '450px',
              textOverflow: 'ellipsis',
            },
          }}
          cancelButtonProps={{
            label: t('Cancel'),
            color: 'secondary',
            onClick: () => setIsDeleteOpened(false),
          }}
          submitButtonProps={{
            label: t('Remove'),
            color: 'error',
            onClick: handleDelete,
          }}
        >
          {t("This action can't be undone.")}
        </Dialog>
      )}
    </Box>
  );
};

export default ActionsCell;
