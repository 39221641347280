import { setAuth, type AxiosInstance } from '@mint-lib/api';
import { AuthService, workspaceAuthStorage } from '@mint-lib/auth';

import type { MintServiceLocatorAbstractFactoryContext } from '../types.js';

const CREDENTIALS = '/api/authentication/v1/auth0/credentials/';

export const getAuth0Credentials = async (guestApi: AxiosInstance) => {
  return guestApi
    .get(CREDENTIALS)
    .then((data) => {
      return data.data;
    })
    .catch(() => {});
};

export async function authFactory(
  ctx: MintServiceLocatorAbstractFactoryContext,
): Promise<AuthService> {
  const guestApi = await ctx.getInstance('http', 'guestApi' as const);
  const auth0Credentials = await getAuth0Credentials(guestApi);

  const auth = new AuthService(
    guestApi,
    workspaceAuthStorage,
    auth0Credentials,
  );

  // TODO: remove this line after migration to new api
  setAuth(auth);
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'auth', ctx.eventBus),
  );
  ctx.addEffect(
    auth.getEventEmitter().addChannel('auth', 'logout', ctx.eventBus),
  );
  return auth;
}
