import camelCase from 'camelcase';
import { snakeCase } from 'snake-case';

type TransformStrategy = (s: string) => string;

type CamelCase<S extends string> =
  S extends `${infer P1}_${infer P2}${infer P3}`
    ? `${Lowercase<P1>}${Uppercase<P2>}${CamelCase<P3>}`
    : Lowercase<S>;

export type KeysToCamelCase<T> = {
  [K in keyof T as CamelCase<string & K>]: T[K] extends any[]
    ? KeysToCamelCase<T[K][0]>[]
    : T[K] extends {}
      ? KeysToCamelCase<T[K]>
      : T[K];
};

type UpperChar =
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z';

type FromCamelCase<
  S extends string,
  Joiner extends string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
> = S extends `${infer Head}${UpperChar}${infer _Tail}`
  ? // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Head extends `${infer _H}${UpperChar}${infer _T}` // get rid of `Head`s that contain upper-case characters - we only want the shortest head up to the first upper-case char.
    ? never
    : S extends `${Head}${infer U}${infer Tail}`
      ? `${Head}${Head extends '' ? '' : Joiner}${Lowercase<U>}${FromCamelCase<
          Tail,
          Joiner
        >}`
      : never
  : S;

type SnakeCase<S extends string> = FromCamelCase<S, '_'>;
type ConstantCase<S extends string> = `${Uppercase<SnakeCase<S>>}`;

export type KeysToSnakeCase<T> = {
  [K in keyof T as SnakeCase<string & K>]: T[K] extends any[]
    ? KeysToSnakeCase<T[K][0]>[]
    : T[K] extends {}
      ? KeysToSnakeCase<T[K]>
      : T[K];
};

export type KeysToConstantCase<T> = {
  [K in keyof T as ConstantCase<string & K>]: T[K] extends any[]
    ? KeysToConstantCase<T[K][0]>[]
    : T[K] extends {}
      ? KeysToConstantCase<T[K]>
      : T[K];
};

export const hasSpecialChars = (str: string) => /[^a-zA-Z0-9_ ]/.test(str);

const objectkeysTo =
  <T, R>(strategy: TransformStrategy) =>
  (object: T): R => {
    if (Array.isArray(object)) {
      // @ts-ignore
      return object.map(objectkeysTo(strategy));
    }

    if (typeof object !== 'object' || object === null) {
      // @ts-ignore
      return object;
    }

    const converted = {};

    Object.keys(object).forEach((key) => {
      // @ts-ignore
      const value = object[key];

      if (value instanceof Object) {
        // @ts-ignore
        converted[strategy(key)] = objectkeysTo(strategy)(value);
      } else {
        // @ts-ignore
        converted[strategy(key)] = value;
      }
    });

    // @ts-ignore
    return converted;
  };

export const snakeToCamel = (str: string) => {
  if (hasSpecialChars(str)) {
    return str;
  }

  return camelCase(str);
};

export const objectKeysToCamelCase: <T extends { [key: string]: any }>(
  obj: T,
) => KeysToCamelCase<T> = objectkeysTo(snakeToCamel);

export const camelToSnake = (str: string) => {
  if (hasSpecialChars(str)) {
    return str;
  }

  return snakeCase(str);
};

export const objectKeysToSnakeCase: <T extends { [key: string]: any }>(
  obj: T,
) => KeysToSnakeCase<T> = objectkeysTo(camelToSnake);
