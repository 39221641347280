import * as React from 'react';

import styled from '@mint-lib/styled';
import DialogM, { DialogProps as DialogPropsM } from '@mui/material/Dialog';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Box from '../Box/Box.jsx';
import Button, { ButtonProps } from '../Button/Button.jsx';
import DialogContent from '../DialogContent/DialogContent.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';
import IconButton from '../IconButton/IconButton.jsx';
import Skeleton from '../Skeleton/Skeleton.jsx';
import Typography from '../Typography/Typography.jsx';

interface BtnProps extends ButtonProps {
  label?: string;
}

export interface DialogProps extends DialogPropsM {
  /**
   * If `true`, the Dialog is open.
   */
  open: DialogPropsM['open'];

  /**
   * If true, hitting escape will not fire the onClose callback.
   */
  /** @uxpinignoreprop */
  disableEscapeKeyDown?: boolean;

  /**
   *  @uxpinignoreprop
   */
  children?: React.ReactNode;

  /**
   * The component used to render the body of the dialog.
   */
  /** @uxpinignoreprop */
  PaperComponent?: DialogPropsM['PaperComponent'];

  /**
   * Override or extend the styles applied to the component. See CSS API https://mui.com/api/dialog/#css for more details.
   * @uxpinignoreprop
   */
  classes?: DialogPropsM['classes'];

  /**
   * Determine the container for scrolling the dialog.
   */
  scroll?: DialogPropsM['scroll'];

  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   * See the `sx` page for more details. https://mui.com/system/the-sx-prop/
   */
  /** @uxpinignoreprop */
  sx?: DialogPropsM['sx'];

  /**
   * Title of a dialog
   */
  title?: string;

  /**
   * Size prop.
   */
  size?: 'xs' | 's' | 'm' | 'lg';

  /**
   * If set, icon will display to the right.
   */
  icon?: Icons;

  /**
   * Object with button props for submit button
   */
  submitButtonProps?: BtnProps | null;

  /**
   * Object with button props for submit button
   */
  cancelButtonProps?: BtnProps | null;

  /**
   * Callback to control modal closing
   */
  onClose: (...args: any) => void;
  /**
   * An array of object to render additional buttons
   */
  additionalActions?: BtnProps[] | null;
  /**
   * Icon color
   */
  iconProps?: Omit<IconProps, 'component'>;
  /**
   * Icon color
   */
  overflow?: 'hidden' | 'visible';
  /**
   * Pass your own submit button
   */
  submitButton?: React.ReactNode;
  /**
   * Pass your own custom title
   */
  customTitle?: React.ReactNode;
}

function Dialog({
  open,
  TransitionProps,
  title,
  size = 's',
  onClose,
  submitButtonProps,
  cancelButtonProps,
  additionalActions,
  icon,
  iconProps,
  scroll = 'paper',
  overflow = 'hidden',
  submitButton,
  customTitle,
  ...props
}: DialogProps) {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth={false}
      size={size}
      scroll={scroll}
      $overflow={overflow}
      {...props}
    >
      <Title>
        {customTitle ? (
          customTitle
        ) : (
          <TitleLeftContainer>
            {!!icon && (
              <Icon data-testid="dialog-icon" component={icon} {...iconProps} />
            )}
            <Typography component="h2" variant="h3">
              {title}
            </Typography>
          </TitleLeftContainer>
        )}
        <IconButton
          aria-label="cross-btn"
          onClick={onClose}
          icon="Close"
          size="small"
          variant="icon"
          color="secondary"
        />
      </Title>
      <DialogContent>{props.children}</DialogContent>
      <Actions>
        <Box>
          {additionalActions?.map(({ label, ...rest }) => (
            <Button size="large" sx={{ marginRight: '0.5rem' }} {...rest}>
              {label}
            </Button>
          ))}
        </Box>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          {!!cancelButtonProps && (
            <Button
              {...cancelButtonProps}
              variant={
                cancelButtonProps?.variant ? cancelButtonProps.variant : 'text'
              }
              color={
                cancelButtonProps?.color ? cancelButtonProps.color : 'primary'
              }
              size="large"
            >
              {cancelButtonProps?.label ? cancelButtonProps?.label : 'Cancel'}
            </Button>
          )}

          {submitButton ? (
            submitButton
          ) : (
            <Button
              {...submitButtonProps}
              variant={
                submitButtonProps ? submitButtonProps.variant : 'contained'
              }
              color={submitButtonProps ? submitButtonProps.color : 'primary'}
              size="large"
            >
              {submitButtonProps?.label ? submitButtonProps?.label : 'Save'}
            </Button>
          )}
        </Box>
      </Actions>
    </StyledDialog>
  );
}

export default Dialog;

const dialogWidth = {
  xs: '360px',
  s: '520px',
  m: '680px',
  lg: '1040px',
};

const dialogMaxHeight = {
  xs: '48%',
  s: '72%',
  m: '84%',
  lg: '96%',
};

const StyledDialog = styled(DialogM, {
  shouldForwardProp: (prop) => prop !== '$overflow',
})<{
  size: 'xs' | 's' | 'm' | 'lg';
  $overflow?: 'hidden' | 'visible';
}>`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.palette.ui.overlay};
  }
  .MuiDialog-paper {
    box-shadow: none;
    overflow-y: ${({ $overflow }) =>
      $overflow === 'visible' ? 'visible' : 'auto'};
    border-radius: 4px;
    width: ${({ size }) => dialogWidth[size]};
    max-height: ${({ size }) => dialogMaxHeight[size]};
    background-color: ${({ theme }) => theme.palette.ui.primary};
    background-image: none;
  }
`;

const Title = styled('div')`
  display: flex;
  min-height: 3.5rem;
  padding: 1rem;
  justify-content: space-between;
  align-items: flex-start;
`;

const Actions = styled('div')`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
`;

const TitleLeftContainer = styled('div')`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
`;
