import { useTranslation } from '@mint-lib/i18n';
import { useProfile } from '@mint-lib/profile-helpers';
import { useNavigate } from '@mint/routing';

import { Tab, Tabs } from '@myn/mui';

import useTab from '../../hooks/useTab/useTab.js';

const UserManagementTabs = () => {
  const { t } = useTranslation('@myn/permissions');

  const { profileService } = useProfile();

  const navigate = useNavigate();

  const activeRoute = useTab();

  const isAgencyCompany = profileService.isAgencyCompany();

  return (
    <Tabs
      value={activeRoute}
      indicatorPosition="bottom"
      orientation="horizontal"
    >
      <Tab
        label={t('Users')}
        value={'user'}
        onClick={() => {
          navigate({
            to: isAgencyCompany
              ? '/agency-hub/users-management/user'
              : '/company-profile/users-management/user',
          });
        }}
      />
      <Tab
        label={t('Roles')}
        value={'role'}
        onClick={() => {
          navigate({
            to: isAgencyCompany
              ? '/agency-hub/users-management/role'
              : '/company-profile/users-management/role',
          });
        }}
      />
    </Tabs>
  );
};

export default UserManagementTabs;
