import React from 'react';

import styled from '@mint-lib/styled';
import ToggleButtonM, {
  ToggleButtonProps as ToggleButtonPropsM,
} from '@mui/material/ToggleButton';

import type { IconsList as Icons } from '../../assets/asyncIcons.jsx';
import Icon, { IconProps } from '../Icon/Icon.jsx';

export interface ToggleButtonProps extends ToggleButtonPropsM {
  /**
   * The content of the component.
   * */
  children?: React.ReactNode;

  /**
   * Custom Prop.
   * The text of the button.
   */
  label?: string;

  /**
   * Custom Prop to replace children.
   * If set, icon will display to the left.
   * Use this Prop to just have a single Icon in the Button.
   * Use the name of the icon from https://material.io/tools/icons.
   */
  startIcon?: Icons;

  /**
   * Custom Prop to replace children.
   * If set, icon will display to the right.
   * Use the name of the icon from https://material.io/tools/icons.
   */
  endIcon?: Icons;

  /**
   * Override or extend the styles applied to the component.
   */
  classes?: ToggleButtonPropsM['classes'];

  /**
   * The color of the button whhen it is in an active state.
   */
  color?: ToggleButtonPropsM['color'];

  /**
   * If true, the keyboard focus ripple is disabled.
   */
  disabledFocusRipple?: boolean;

  /**
   * If true, the ripple effect is disabled.
   * ⚠️ Without a ripple there is no styling for :focus-visible by default.
   */
  disabledRipple?: boolean;

  /**
   * The size of the button.
   */
  size?: ToggleButtonPropsM['size'];

  /**
   * The value to associate with the button when selected in a ToggleButtonGroup.
   */
  value: string;

  /**
   * If `true`, the button will take up the full width of its container.
   */
  fullWidth?: boolean;

  /**
   * If true, the component is disabled.
   */
  disabled?: boolean;

  /**
   * If true, the button is rendered in an active state.
   */
  selected?: boolean;

  /**
   * Custom Prop
   */
  onChange?: ToggleButtonPropsM['onChange'];

  /**
   * Element to display at the start of the button, such as an icon or text.
   */
  startAdornment?: React.ReactNode;

  sx?: ToggleButtonPropsM['sx'];
  icon?: Icons;
  iconProps?: IconProps;
}

function ToggleButton({
  icon,
  label,
  startIcon,
  endIcon,
  iconProps,
  startAdornment,
  ...rest
}: ToggleButtonProps) {
  return (
    <StyledToggleButton {...rest} $icon={icon}>
      {startAdornment}
      {/* TODO: replace all the icons with startAdornment in all micros */}
      {startIcon && <StartIcon component={startIcon} />}
      {label}
      {icon ? <Icon component={icon} {...iconProps} /> : null}
      {endIcon && <EndIcon component={endIcon} />}
    </StyledToggleButton>
  );
}

export default ToggleButton;

const StartIcon = styled(Icon)({
  marginRight: '0.5rem',
});
const EndIcon = styled(Icon)({
  marginLeft: '0.5rem',
});
const StyledToggleButton = styled(ToggleButtonM, {
  shouldForwardProp: (prop) => prop !== '$icon',
})<{
  $icon?: Icons;
}>(({ $icon }) => ({
  '&.MuiToggleButton-sizeLarge': {
    padding: $icon ? '0.75rem' : '11px 1rem',
  },
  '&.MuiToggleButton-sizeMedium': {
    padding: $icon ? '0.5rem' : '7px 1rem',
  },
  '&.MuiToggleButton-sizeSmall': {
    padding: $icon ? '0.25rem' : '3px 1rem',
  },
}));
