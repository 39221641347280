import type { UseFormReturn } from 'react-hook-form';

import { useTranslation } from '@mint-lib/i18n';

import ConditionalSkeleton from '../../atoms/ConditionalSkeleton/ConditionalSkeleton.jsx';
import InputField from '../../atoms/InputField/InputField.jsx';
import { UserModalType } from './validation.js';

export type EmailViewProps = {
  formInstance: UseFormReturn<UserModalType>;
  isLoading: boolean;
};

const EmailView = ({ formInstance, isLoading }: EmailViewProps) => {
  const { t } = useTranslation('@myn/permissions');

  return (
    <ConditionalSkeleton width={488} height={60} isLoading={isLoading}>
      <InputField
        infoText={t('Enter the email of the user you would like to add')}
        InfoTextTooltipProps={{ placement: 'top' }}
        label={t('Email*')}
        placeholder={t('Enter email')}
        error={!!formInstance.formState.errors.email?.message}
        helperText={formInstance.formState.errors.email?.message}
        onChange={() => {
          formInstance.clearErrors('isActive');
        }}
        autoFocus={true}
        name="email"
      />
    </ConditionalSkeleton>
  );
};

export default EmailView;
