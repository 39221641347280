import { createFileRoute, redirect } from '@tanstack/react-router';

export const Route = createFileRoute('/_secured/agency-hub/users-management/')({
  beforeLoad: async ({ context, search }) => {
    throw redirect({
      to: '/agency-hub/users-management/user',
      replace: true,
    });
  },
});
